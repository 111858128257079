<template>
  <el-dialog
    :title="dataForm.id?'修改':'新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      label-width="80px"
    >
      <el-form-item
        label="标题"
        prop="title"
      >
        <el-input
          v-model="dataForm.title"
          placeholder="请输入标题"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="检测项目"
        prop="detectionItem"
      >
      <el-select v-model="dataForm.detectionItem" style="width: 100%">
          <el-option
            v-for="item in testItems"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="内容"
        prop="content"
      >
        <quill-editor
      v-model="dataForm.content"
      :options="editorOptions"
      placeholder="请输入内容"
      class="editor-height"
    />
      </el-form-item>
      <el-form-item
        label="开始范围"
        prop="startScope"
      >
      <el-input-number
          v-model="dataForm.startScope"
          :min="0.1"
          precision="1"
          controls-position="right"
          size="large"
          placeholder="请输入开始范围"
        />
      </el-form-item>
      <el-form-item
        label="结束范围"
        prop="endScope"
      >
        <el-input-number
          v-model="dataForm.endScope"
          :min="0.1"
          precision="1"
          controls-position="right"
          size="large"
          placeholder="请输入结束范围"
        />
      </el-form-item>
      <el-form-item
        label="开始年龄"
        prop="startAge"
      >
      <el-input-number
          v-model="dataForm.startAge"
          :min="1"
          :max="99"
          controls-position="right"
          size="large"
          placeholder="请输入开始年龄"
        />
      </el-form-item>
      <el-form-item
        label="结束年龄"
        prop="endAge"
      >
      <el-input-number
          v-model="dataForm.endAge"
          :min="1"
          :max="100"
          controls-position="right"
          size="large"
          placeholder="请输入结束年龄"
        />
      </el-form-item>
      <el-form-item
        label="优先级"
        prop="level"
      >
      <el-input-number
          v-model="dataForm.level"
          :min="1"
          controls-position="right"
          size="large"
          placeholder="请输入优先级"
        />
      </el-form-item>
      <el-form-item
        label="是否启用"
        prop="isEnable"
      >
        <el-radio-group v-model="dataForm.isEnable">
          <el-radio label="1">启用</el-radio>
          <el-radio label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">取 消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
 import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      visible: false,
      dataForm: {
        title: '',
        detectionItem: '',
        content: '',
        isEnable: '1',
        startScope: '',
        endScope: '',
        startAge: '',
        endAge: '',
        level: '',
        customerId: '',
      },
      dataRule: {
        title: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
      },
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],        // 加粗、斜体、下划线
            [{ 'list': 'ordered'}, { 'list': 'bullet' }], // 列表
            ['clean']                                 // 清除格式
          ]
        }
      },
      testItems: [],
    };
  },
  created() {},
  methods: {
    init(id) {
      this.visible = true;
      this.getDetectionItemList();
      this.dataForm = {
        title: '',
        detectionItem: '',
        content: '',
        isEnable: '1',
        startScope: '',
        endScope: '',
        startAge: '',
        endAge: '',
        level: '',
        customerId: '',
      }
      if (id) {
      let loginUserInfo = this.$store.state.loginUserInfo;
        const resData = {
          id,
          customerId: loginUserInfo.isAdmin ? 'ALL' : (loginUserInfo.userId || null),
        }
        this.$http.post(`dbVerbalTrick/getVerbalTrickDetail`, resData).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            delete data.password;
            if (data) this.dataForm = data;
          }
        });
      } else {
        this.dataForm = {
          ...this.dataForm,
          customerId: localStorage.getItem("predicthealthUserInfo").id,
        };
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "dbVerbalTrick/saveVerbalTrick";
          let data = this.dataForm;
          if(data.id) {
            url = "dbVerbalTrick/editVerbalTrick";
          }else {
            let loginUserInfo = this.$store.state.loginUserInfo;
            if(loginUserInfo.isAdmin) {
              data.customerId = 'ALL';
            } else {
              data.customerId = loginUserInfo.userId;
            }
          }
          this.$http.post(`${url}`, data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
    getDetectionItemList() {
      this.$http.get(`/dbVerbalTrick/getDetectionItemList`).then((res) => {
        if (res.data.code == 200) {
        this.testItems = res.data?.data || [];
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.editor-height {
  :deep(.ql-container) {
    height: 300px;
  }
}
</style>